import * as React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, graphql, Link } from 'gatsby';
import AlbumList from 'components/album-list/AlbumList';
import { AlbumItem } from 'components/album-item/AlbumItem';
import { AlbumsLayout } from 'components/albums-layout/AlbumsLayout';


export default () => (
  <>
  <Helmet title="Best Icelandic Albums of 2019">
    <script>
      document.body.classList.add('loading');
    </script>
    <script src={withPrefix('albums.js')} type="text/javascript" />
  </Helmet>

    <AlbumsLayout
      title="Best Icelandic Albums of 2019"
    >
      <AlbumList>
        <AlbumItem
          position="20"
          artist="Mr. Silla"
          album="Hands on Hands"
          link="https://open.spotify.com/album/66keDJlUxD7A8VqBvEZ07B"
          image={require('assets/images/20-mrsilla.jpg')}
        />
        <AlbumItem
          position="19"
          artist="kef LAVÍK"
          album="Blautt heitt langt vont sumar"
          link="https://open.spotify.com/album/2F8mYlLqhex0OyYqqWzomI"
          image={require('assets/images/19-keflavik.jpg')}
        />
        <AlbumItem
          position="18"
          artist="Konsulat"
          album="& Rósir"
          link="https://open.spotify.com/album/7jlZt7CEmSSoJytyg3hl41"
          image={require('assets/images/18-konsulat.jpg')}
        />
        <AlbumItem
          position="17"
          artist="Tumi Árnason & Magnús Trygvason Elíasen"
          album="Allt er ómælið"
          link="https://open.spotify.com/album/6DzVHT3OtLD1xZLm3OsBar"
          image={require('assets/images/17-allt.jpg')}
        />
        <AlbumItem
          position="16"
          artist="TSS"
          album="Rhino"
          link="https://open.spotify.com/album/406I9V6lmPGnUvAsvF4CvO"
          image={require('assets/images/16-tss.jpg')}
        />

        <AlbumItem
          position="15"
          artist="Myrra Rós"
          album="Thought Spun"
          link="https://open.spotify.com/album/1esMXYDXRIObqN2ffkaokO"
          image={require('assets/images/15-myrra.jpg')}
        />
        <AlbumItem
          position="14"
          artist="Ásta"
          album="Sykurbað"
          link="https://open.spotify.com/album/571kqf9EFLVsPnRZIvM9m5"
          image={require('assets/images/14-sykurbad.jpg')}
        />
        <AlbumItem
          position="13"
          artist="Sunna"
          album="Art of History"
          link="https://open.spotify.com/album/5uDHGHAyvaTONcAP8PsEEU"
          image={require('assets/images/13-sunna.jpg')}
        />
        <AlbumItem
          position="12"
          artist="Rauður"
          album="Semilunar"
          link="https://open.spotify.com/album/6Jn9WFjTKIqZem63Weu3ZE"
          image={require('assets/images/12-raudur.jpg')}
        />
        <AlbumItem
          position="11"
          artist="Sin Fang"
          album="Sad Party"
          link="https://open.spotify.com/album/0PaKlZQCXMrQvtlxxcfuZK"
          image={require('assets/images/11-sinfang.jpg')}
        />
        <AlbumItem
          position="10"
          artist="Gróa"
          album="Í glimmerheimi"
          link="https://open.spotify.com/album/3kav551BtlcoVBasdndeOY"
          image={require('assets/images/10-groa.jpg')}
        />
        <AlbumItem
          position="09"
          artist="Korter í flog"
          album="Anna & Bernard Blume (drepa alla fasista)"
          link="https://open.spotify.com/album/739M5BULnPtqWPa3UlwDgo?si=PoO-Q-I_Tlau9eXnbGRsfw"
          image={require('assets/images/9-korter.jpg')}
        />
        <AlbumItem
          position="08"
          artist=" Kristín Anna"
          album="I Must Be The Devil"
          link="https://open.spotify.com/album/7iku5X7Tc8UKcszi6cC2u2"
          image={require('assets/images/8-kristin.jpg')}
        />
        <AlbumItem
          position="07"
          artist="Laura Secord"
          album="Ending Friendships"
          link="https://open.spotify.com/album/2ozWaHAZngKyghIfYt5ldI"
          image={require('assets/images/7-laura.jpg')}
        />
        <AlbumItem
          position="06"
          artist="Markús"
          album="Counting Sad Songs"
          link="https://open.spotify.com/album/3uBKOv9e0mPmoVP2bPjcrS"
          image={require('assets/images/6-markus.jpg')}
        />
        <AlbumItem
          position="05"
          artist="Hildur Guðnadóttir"
          album="Chernobyl"
          image={require('assets/images/5-hildur.jpg')}
          link="https://open.spotify.com/album/5q53mBxAsq77kK9aCHcAtZ"
        />
        <AlbumItem
          position="04"
          artist="K.óla"
          album="Allt verður alltílæ"
          image={require('assets/images/4-kola.jpg')}
          link="https://open.spotify.com/album/2g4SkeJfpWnfthp1Mek6Am"
        />
        <AlbumItem
          position="03"
          artist="Skoffín"
          album="Bjargar heiminum"
          image={require('assets/images/3-skoffin.jpg')}
          link="https://open.spotify.com/album/4GmoHmbw5As2CGJCvd5jsG"
        />
        <AlbumItem
          position="02"
          artist="Bjarki"
          album="Happy Earthday"
          image={require('assets/images/2-bjarki.jpg')}
          link="https://open.spotify.com/album/4oNa0hWev9GmN96T0z1mRk"
        />
        <AlbumItem
          position="01"
          artist="Grísalappalísa"
          album="Týnda rásin"
          image={require('assets/images/1-tyndarasin.jpg')}
          link="https://open.spotify.com/album/0rDq7nSRAqi7SntBNauoni"
        />
      </AlbumList>
    </AlbumsLayout>

  </>
);
