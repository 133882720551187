import React, { useState } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { TimelineLite } from 'gsap';
import { UIContext } from 'context/ui';
import waitForGlobal from 'utils/waitForGlobal';

import s from './AlbumsLayout.scss';

interface IAlbumsLayoutProps {
  children: React.ReactNode;
  title: string;
}

export const AlbumsLayout = ({ children, title, location }: IAlbumsLayoutProps) => {
  const {
    overlayActive,
    setOverlayActive,
    activePosition,
    setActivePosition,
    activeArtist,
    activeAlbum,
    activeArtwork,
    activeTitle,
    activeLink, activeQuote, setActiveAlbum } = React.useContext<any>(UIContext);

  const refAlbum = React.useRef<HTMLDivElement>(null);
  const refHeading = React.useRef<HTMLHeadingElement>(null);
  const refLogo = React.useRef<HTMLHeadingElement>(null);

  const onClose = () => {
    setOverlayActive(false);

    const t = new TimelineLite();

    t.addLabel('start');

    if (refAlbum.current) {
      t.fromTo(
        refAlbum.current,
        0.8,
        { autoAlpha: 1, y: '0px' },
        { autoAlpha: 0, y: '-40px', ease: 'Power4.easeInOut' },
        'start',
      );

      if (refHeading.current) {
        t.fromTo(
          refHeading.current,
          1,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start',
        );
      }
    }
  };

  // stagger in on viewport enter
  const onEnter = () => {
    if (slideCount <= 1) { return; }

    const t = new TimelineLite();
    const childNodes = track.current!.childNodes;
    // only animate first potentially visible ones
    const items = Array.from(childNodes).slice(0, slideCount);
    t.staggerFromTo(items, 0.75, {
      x: 40, autoAlpha: 0,
    },
    {
      x: 0, autoAlpha: 1,
    }, 0.15);
  };

  React.useEffect(() => {
    const t = new TimelineLite();

    if (overlayActive) {
      t.addLabel('start');

      if (refHeading.current) {
        t.fromTo(
          refHeading.current,
          0.8,
          { autoAlpha: 1, y: '0px' },
          { autoAlpha: 0, y: '-20px', ease: 'Power4.easeInOut' },
          'start',
        );
      }

      if (refAlbum.current) {
        t.fromTo(
          refAlbum.current,
          1,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start',
        );
      }
    } else {
      t.addLabel('start');

      if (refHeading.current) {
        t.fromTo(
          refHeading.current,
          1,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start',
        );
      }
    }
  }, [overlayActive]);

  React.useEffect(() => {
    waitForGlobal('Emblem').then(() => setupRotate());
    waitForGlobal('AlbumCollage').then(() => setupAlbum());
  }, []);

  const setupRotate = () => {
    Emblem.init('#zultans');
    const t = new TimelineLite();

    t.addLabel('start');

    if (refLogo.current) {
      t.fromTo(
        refLogo.current,
        0.8,
        { autoAlpha: 0, y: '20px' },
        { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
        'start',
      );
    }
  };

  const setupAlbum = () => {
    AlbumCollage.init();
  };

  return (
    <div className={s.albumsLayout} id="albumslayout">
      <div className={s.albumsLayout__plane}>
        <Link to="/" className={s.albumsLayout__logo} ref={refLogo} id="zultans">ZóldánarSveiflunnar</Link>
        <h2 className={s.albumsLayout__heading} ref={refHeading}>{title}</h2>
          <div className={s.albumsLayout__album} ref={refAlbum}>
            <button className={s.albumsLayout__close} onClick={onClose}>
              <svg className={s.albumsLayout__closeSvg} width="14px" height="16px" x="0px" y="0px" viewBox="0 0 14 14">
                <polygon fill="currentColor" points="8.4,7.1 13.8,1.6 12.4,0.2 7,5.6 1.6,0.2 0.2,1.6 5.6,7.1 5.6,7.1 0.2,12.4 1.6,13.8 7,8.4 7,8.4
              	12.4,13.8 13.8,12.4 "/>
              </svg>
            </button>
            <div className={s.albumsLayout__head}>
              <h3 className={s.albumsLayout__title}>{activePosition}. <br />{activeArtist}<br/>{activeAlbum}</h3>
              <img
                className={s.albumsLayout__image}
                src={activeArtwork}
              />
            </div>
            <a  className={s.albumsLayout__link} href={activeLink} target="_blank">Listen on Spotify</a>
          </div>
      </div>
      <div className={s.albumsLayout__list}>
        {children}
      </div>
    </div>
  );
};
