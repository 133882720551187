import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { UIContext } from 'context/ui';

import s from './AlbumItem.scss';

interface IProps {
  children: React.ReactNode;
  position: string;
  artist: string;
  album: string;
  image: any;
  source?: string;
  link?: string;
  quote?: string;
}

export const AlbumItem = ({  position, artist, album, image, source, link, quote }: IAlbumsLayoutProps) => {
  const {
    setOverlayActive,
    setActivePosition,
    setActiveArtwork,
    setActiveAlbum, setActiveArtist, setActiveQuote, setActiveLink } = React.useContext<any>(UIContext);

  const onOpen = () => {
    setActivePosition(position);
    setActiveArtwork(image);
    setActiveArtist(artist);
    setActiveAlbum(album);
    setActiveQuote(quote);
    setActiveLink(link);
    setOverlayActive(true);
  }

  return (
    <div onClick={onOpen} className={s(s.albumItem, 'album')}>
      <h1 className={s.albumItem__heading}>
        <span className={s.albumItem__number}>{position}</span>
        <span className={s.albumItem__title}>
          <span className={s.albumItem__text}>{artist} ~ {album}</span>
        </span>
      </h1>
      <img
        className={s.albumItem__image}
        src={image}
      />
    </div>
  );
};
