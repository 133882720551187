import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { Link } from 'gatsby';

import s from './AlbumList.scss';

interface IProps {
  children: React.ReactNode;
}

export default class AlbumList extends PureComponent<IProps> {

  init = () => {
    const { children } = this.props;
  }

  animate = () => {
    // some basic
  }

  render() {
    const { children } = this.props;

    return (
      <div className={s.albumList} id="albums">
        {children}
      </div>
    );
  }
}
